<template>
  <div class="language-selector">
    <div
        class="language-selector__current-language"
        :style="`background-image:url('../images/flags/languages/${currentLanguage}.svg')`"
        @click="isSelecting = !isSelecting">
        {{ translate(`language_${currentLanguage}`) }}
    </div>
    <div class="language-selector__options" v-if="isSelecting">
        <ul>
            <li v-for="(language, index) of selectableLanguages" :key="index">
                <button
                    :style="`background-image:url('../images/flags/languages/${language}.svg')`"
                    @click.prevent="selectLanguage(language)">
                    {{ translate(`language_${language}`) }}
                </button>
            </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        currentLanguage: {
            type: String,
            required: false,
            default: 'de'
        }
    },

    data () {
        return {
            isSelecting: false,
            languages: [
                'de',
                'en',
                'cs',
                'pl',
            ]
        }
    },

    computed: {
        selectableLanguages () {
            return this.languages.filter(
                language => language !== this.currentLanguage
            )
        }
    },

    methods: {
        selectLanguage (language) {
            let languageSubdomain = location.hostname.split('.')[0]

            if (
                languageSubdomain.length !== 2 &&
                languageSubdomain.toLowerCase() !== 'zh-cn' &&
                languageSubdomain.toLowerCase() !== 'zh-tw'
            ) {
                languageSubdomain = 'de'
            }

            location.href = location.protocol
                + '//' + (language === 'de' ? '' : language + '.')
                + location.hostname
                    .replace('www.', '')
                    .replace(RegExp('^' + languageSubdomain + '\\.'), '')
                + location.pathname
                + location.search
        }
    }
}
</script>

<template>
    <div id="buyInvestment">
        <div id="buyInvestmentOverlay" v-if="showForm">
            <div class="investmentContainer">
                <div class="headerContainer">
                    <div class="closeIcon" @click="showForm=false">
                        <i class="icon-close"></i>
                    </div>
                </div>
                <div class="bodyContainer">
                    <section id="jumbo" :style="{backgroundImage:`url(${selectedProject.jumboImage})`}">
                        <div class="container tabsContainer d-none d-md-block">
                            <div class="row">
                                <div class="col-12 col-xl-10 offset-xl-1">
                                    <button :class="['btn btn-light big mr-3', this.selectedSite == 'description' ? 'btn-light' : 'btn-dark']" @click="openOverlay(selectedProject.id, 'description')">{{ translate('project_description') }}</button>
                                    <button :class="['btn btn-light big mr-3', this.selectedSite == 'details' ? 'btn-light' : 'btn-dark']" @click="openOverlay(selectedProject.id, 'details')">{{ translate('project_details') }}</button>
                                    <button :class="['btn btn-light big', this.selectedSite == 'news' ? 'btn-light' : 'btn-dark']" @click="openOverlay(selectedProject.id, 'news')">{{ translate('news') }}</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="container mt-3">
                        <div class="row">
                            <div class="col-12 col-xl-10 offset-xl-1">
                                <div class="logo text-center">
                                    <img :src="selectedProject.logo" />
                                </div>
                                <h1>{{selectedProject.title}}</h1>
                                <div v-html="selectedProject.text" v-if="this.selectedSite != 'news'"></div>
                                <div v-else id="newsTable">
                                    <div v-for="news in selectedProject.text" :key="news.id">
                                        <div class="newsContainer">
                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="image">
                                                        <img :src="news.image" />
                                                    </div>
                                                </div>
                                                <div class="col-8">
                                                    <h2 class="mt-0">{{news.title}}</h2>
                                                    {{news.description}}
                                                    <br><br>
                                                    <button class="btn btn-secondary arrow" data-toggle="collapse" :data-target="'#news'+news.id" aria-expanded="false">{{ translate('read_more') }}</button>
                                                </div>
                                                <div class="col-12 collapse show" :id="'news'+news.id" data-parent="#newsTable" v-html="news.content"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="riskFrame" class="col-12 col-lg-10 text-center mb-5 mt-5">
                                    <div class="frame mb-5 mt-5 " v-html="translate('info_risk')"></div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="1" v-model="riskCheck">
                                            <label class="form-check-label" for="riskCheck">
                                                <b>{{ translate('info_risk_no_title') }}</b>
                                            </label>
                                            <span class="invalid-feedback" role="alert" v-if="error">
                                                <strong>{{ translate('error_approval_required') }}</strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center mt-3">
                                    <div class="col-12 col-sm-6 text-center">
                                        <button v-if="disablebuy === 1" class="btn btn-secondary mb-4" @click="buyInvetsmentConfirmation" disabled>{{ translate('purchase') }}</button>
                                        <button v-else class="btn btn-secondary mb-4" @click="buyInvetsmentConfirmation">{{ translate('purchase') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-almostWhite pb-5">
            <div class="container">
                <div class="row">
                    <div class="col-xl-10 offset-xl-1">
                        <table class="d-none d-sm-table">
                            <thead>
                                <tr>
                                    <td>{{ translate('projects') }}</td>
                                    <td>{{ translate('contract_type') }}</td>
                                    <td>{{ translate('due_date') }}</td>
                                    <td>{{ translate('investment_value') }}</td>
                                    <td>{{ translate('buying_price') }}</td>
                                    <td>{{ translate('expected_payback') }}</td>
                                    <td>{{ translate('expected_remaining_term') }}</td>
                                    <td>{{ translate('predicted_revenue_alt') }}</td>
                                    <td>{{ translate('predicted_return') }}</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody id="investmentTable">
                                <template v-for="investment in investments">
                                    <tr :key="investment.id">
                                        <td><a :href='investment.nice_url'>{{ investment.bezeichnung }}</a></td>
                                        <td>{{ translate(investment.vertragsart) }}</td>
                                        <td>{{ investment.falligkeit }}</td>
                                        <td>{{ investment.total | money }}</td>
                                        <td>{{ investment.kaufpreis | money }}</td>
                                        <td>{{ investment.ruckzahlung | money }}</td>
                                        <td>{{ investment.restlaufzeit }} Monate</td>
                                        <td>{{ investment.progErtrag | money }}</td>
                                        <td>{{ translate('interest_per_year', [{ key: 'INTEREST_VALUE', value: investment.progRendite }]) }}</td>
                                        <td><button class="btn btn-lightest small" data-toggle="collapse" :data-target="'#'+investment.id" aria-expanded="false" @click="getDetails(investment.id)">{{ translate('purchase') }}</button></td>
                                    </tr>
                                    <tr :key="investment.id+'details'">
                                        <td class="details" colspan="10">
                                            <div :id="investment.id" class="detail collapse" data-parent="#investmentTable">
                                                <div class="title text-center"  v-if="!(investment.id in investmentsDetails)">{{ translate('loading_please_wait') }}</div>
                                                <div class="row" v-else>
                                                    <div class="col-12 col-md-8">
                                                        <div class="singleProject">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="description" v-html="investmentsDetails[investment.id].descriptionShort"></div>
                                                                    <div class="imageData">
                                                                        <div class="row">
                                                                            <div class="col-12 mb-4 col-sm-7 mb-sm-0 col-md-12 mb-md-4 offset-0 col-lg-8 offset-lg-2 col-xl-7 mb-xl-0 offset-xl-0">
                                                                                <div :data-bcgimage="investmentsDetails[investment.id].mainImage" class="imageContainer">
                                                                                    <img :data-src="investmentsDetails[investment.id].mainImage" src="" class="d-block d-sm-none d-md-block d-xl-none" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 col-sm-5 col-md-12 offset-0 col-lg-8 offset-lg-2 col-xl-5 offset-xl-0">
                                                                                <div class="row">
                                                                                    <div class="col-12 col-md-8 col-xl-12">
                                                                                        <table>
                                                                                            <tr>
                                                                                                <td colspan="2">
                                                                                                    {{investmentsDetails[investment.id].contract}}
                                                                                                    <img src="/images/icons/flag-de.svg" v-if="investmentsDetails[investment.id].law == '43'" />
                                                                                                    <img src="/images/icons/flag-aut.svg" v-else />
                                                                                                </td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{{ translate('funding_threshould') }}</td>
                                                                                                <td v-if="investmentsDetails[investment.id].threshold > 0"><span class="ntl-monetary">EUR {{investmentsDetails[investment.id].threshold | money}}</span></td>
                                                                                                <td v-else><span class="ntl-monetary">—</span></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{{ translate('funding_limit') }}</td>
                                                                                                <td><span class="ntl-monetary">EUR {{investmentsDetails[investment.id].limit | money}}</span></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{{ translate('invested') }}</td>
                                                                                                <td><span class="ntl-monetary">EUR {{investmentsDetails[investment.id].invested | money}}</span></td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{{ translate('number_of_investors') }}</td>
                                                                                                <td>{{investmentsDetails[investment.id].numberOfInvestments}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{{ translate('contract_end') }}</td>
                                                                                                <td>{{investmentsDetails[investment.id].endDate}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{{ translate('investment_status') }}</td>
                                                                                                <td>{{investmentsDetails[investment.id].status}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{{ translate('planned_payback') }}</td>
                                                                                                <td>{{investmentsDetails[investment.id].payBackDate}}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{{ translate('nominal_interest_rate') }}</td>
                                                                                                <td>{{ translate('interest_per_year', [{ key: 'INTEREST_VALUE', value: $options.filters.money(investmentsDetails[investment.id].interests) }]) }}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td colspan="2" class="text-right">
                                                                                                    <button class="btn btn-secondary" @click="openOverlay(investment.id, 'description')">{{ translate('purchase') }}</button>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4 offset-lg-1 col-lg-3">
                                                        <h3>{{ translate('documents') }}</h3>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div v-for="document in investmentsDetails[investment.id].documents" :key="document.id">
                                                                    <a :href="'/showFile/'+document.file" class="document" target="_blank">{{document.title}}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Xhr from '../core/xhr';
    import Vue from 'vue';
    import $ from 'jquery'
    import InView from '../modules/in-view'

    export default {
        props: {
            investments: Array,
            disablebuy: Number
        },

        methods: {
            getDetails(invoiceId) {
                if(!(invoiceId in this.investmentsDetails)) {
                    new Xhr({invoiceId: invoiceId}).post('/backend/dashboard/getBuyInvestmentData').then((response) => {
                        Vue.set(this.investmentsDetails, invoiceId, response);
                    })
                }
                setTimeout(function() {
                    let inview = new InView();

                    inview.observe('img[data-src]', (img) => {
                        img.src = img.dataset.src;
                    });

                    inview.observe('div[data-bcgimage]', (el) => {
                        el.style = "background-image: url(" + el.dataset.bcgimage + ")";
                    });
                }, 500);
            },

            openOverlay(id, site) {

                this.selectedProject =
                {
                    "id": id,
                    "title": this.investmentsDetails[id].title,
                    "logo": "/showFile/"+this.investmentsDetails[id].logo,
                }

                if(site == "description") {
                    this.selectedProject.jumboImage = this.investmentsDetails[id].jumboImageDescription;
                    this.selectedProject.text = this.investmentsDetails[id].description;
                    this.selectedSite = "description";
                }
                if(site == "details") {
                    this.selectedProject.jumboImage = this.investmentsDetails[id].jumboImageDetail;
                    this.selectedProject.text = this.investmentsDetails[id].details;
                    this.selectedSite = "details";
                }
                if(site == "news") {
                    this.selectedProject.jumboImage = this.investmentsDetails[id].jumboImageDescription;
                    this.selectedProject.text = this.investmentsDetails[id].news;
                    this.selectedSite = "news";
                }


                this.showForm = true;
                setTimeout(function() {
                    $('.carousel').flickity();
                }, 500);

                if(site == "news") {
                    let news = this.selectedProject.text;

                    setTimeout(function(news) {
                        for (let i = 0; i < news.length; i++) {
                            $("#news"+news[i].id).collapse('hide');
                        }

                    }, 500, news);
                }
            },

            buyInvetsmentConfirmation() {
                if(this.riskCheck == false) {
                    this.error = true;
                } else {
                    this.error = false;
                    new Xhr({invetmentId: this.selectedProject.id}).post('/backend/dashboard/buyInvetsmentConfirmation').then((response) => {
                        this.showForm = false;
                    })
                }
            }
        },

        filters: {
            money(value) {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((value));
            }
        },
        data() {
            return {
                showForm: false,
                investmentsDetails: {},
                selectedProject: {},
                selectedSite: 'description',
                error: false,
                riskCheck: false
            };
        }
    }
</script>

<template>
    <div class="newsletter-registration-form">

        <form @submit.prevent="submit">

            <div v-if="!isSubmitted">
                <div class="input-wrapper">
                    <input type="text" :placeholder="translate('first_name')" v-model="firstName" required>
                </div>
                <div class="input-wrapper">
                    <input type="text" :placeholder="translate('last_name')" v-model="lastName" required>
                </div>
                <div class="input-wrapper">
                    <input type="email" :placeholder="translate('email')" v-model="email" maxlength="255" required>
                </div>
                <div class="input-wrapper checkbox-input-wrapper input-wrapper-large-spacing">
                    <input type="checkbox" id="newsletter-form-privacy-policy-checkbox" required v-model="privacyPolicyAccepted">
                    <label for="newsletter-form-privacy-policy-checkbox" v-html="translate('accept_privacy_policy')"></label>
                </div>
                <br>
                <div class="g-recaptcha" :data-sitekey="recaptchasitekey"></div>
                <div class="input-wrapper input-wrapper-large-spacing">
                    <button type="submit" class="btn btn-alt btn-secondary double-arrow" :disabled="submitDisabled">{{ translate('newsletter_register') }}</button>
                </div>
            </div>

            <div class="message" :class="{ 'message-is-error': hasError, 'message-is-success': isSubmitted }" v-if="hasMessage" v-html="message"></div>

        </form>

    </div>
</template>

<script>
import Xhr from '../core/xhr';

export default {
    props: {
        recaptchasitekey: String
    },
    data () {
        return {
            firstName: '',
            lastName: '',
            email: '',
            privacyPolicyAccepted: false,
            isSubmitting: false,
            isSubmitted: false,
            hasError: false,
            message: ''
        };
    },

    computed: {
        requiredFieldsFilled () {
            return this.firstName.trim() !== ''
                && this.lastName.trim !== ''
                && this.email.trim !== ''
                && this.privacyPolicyAccepted;
        },
        submitDisabled () {
            return !this.requiredFieldsFilled || this.isSubmitting || this.isSubmitted;
        },
        hasMessage () {
            return this.message.trim() !== ''
        }
    },

    methods: {
        submit () {
            if (this.submitDisabled) {
                return;
            }

            this.message = `<strong>${this.translate('newsletter_sending')}</strong>`;
            this.hasError = false;
            this.isSubmitting = true;

            new Xhr({
                vorname: this.firstName,
                name: this.lastName,
                email: this.email,
                privacy: this.privacyPolicyAccepted,
                grecaptcharesponse: document.getElementById('g-recaptcha-response').value
            }).post('/api/newsletter/submit')
                .then(response => {
                    if (!response.success) {
                        this.hasError = true;
                        this.message = this.translate(response.error);
                        return;
                    }

                    this.isSubmitted = true;
                    this.message = this.translate('newsletter_registration_success');
                })
                .catch(err => {
                    this.hasError = true;
                    this.message = this.translate('newsletter_registration_error');
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        }
    }
}
</script>

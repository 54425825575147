import Vue from 'vue'
import components from '../components'
import Modules from '../modules'
import Xhr from './xhr'
import Dispatcher from './dispatcher'
import Inview from 'vueinview'
import butterup from 'butteruptoasts';

import translationHandler from './translation-handler'

export default class App {
  constructor () {
    this.xhr = Xhr
    this.dispatcher = new Dispatcher()
  }

  async init (url) {
    this.url = url;

    // Expose butterup to be able to trigger toasts from anywhere
    // See https://butteruptoast.com/ for usage instructions
    butterup.options.maxToasts = 1;
    butterup.options.toastLife = 5000;
    window.butterup = butterup;

    // Frontend translation handling

    // We expose the lines globally so they can also be used outside of the
    // translation handler, if needed for some reason

    window.dagobertTranslationLines = null

    try {
        dagobertTranslationLines = await translationHandler.loadTranslationLines()
    } catch {} // We don't need to handle this error further

    window.dagobertTranslate = (key, data = null) => {
        return translationHandler.translate(key, data)
    }

    Vue.mixin({
      methods: {
        translate (key, data = null) {
          return window.dagobertTranslate(key, data)
        }
      }
    })

    Vue.use(Inview)

    // https://gist.github.com/AnalyzePlatypus/22ca31c8f953db92eedadfe930bce31f
    Vue.directive('click-outside', {
        bind: function (el, binding, vnode) {
            el.eventSetDrag = function () {
                el.setAttribute('data-dragging', 'yes')
            }
            el.eventClearDrag = function () {
                el.removeAttribute('data-dragging')
            }
            el.eventOnClick = function (event) {
                var dragging = el.getAttribute('data-dragging')
                // Check that the click was outside the el and its children, and wasn't a drag
                if (!(el == event.target || el.contains(event.target)) && !dragging) {
                    // call method provided in attribute value
                    vnode.context[binding.expression](event)
                }
            };
            document.addEventListener('touchstart', el.eventClearDrag)
            document.addEventListener('touchmove', el.eventSetDrag)
            document.addEventListener('click', el.eventOnClick)
            document.addEventListener('touchend', el.eventOnClick)
        }, unbind: function (el) {
            document.removeEventListener('touchstart', el.eventClearDrag)
            document.removeEventListener('touchmove', el.eventSetDrag)
            document.removeEventListener('click', el.eventOnClick)
            document.removeEventListener('touchend', el.eventOnClick)
            el.removeAttribute('data-dragging')
        }
    })

    this.vue = new Vue({
      el: '#app',
      url,
      components
    })

    this.inview = new Modules.InView();

    this.inview.observe('img[data-src]', (img) => {
      img.src = img.dataset.src;
    });

    this.inview.observe('div[data-bcgimage]', (el) => {
      el.style = "background-image: url(" + el.dataset.bcgimage + ")";
    });

    this.inview.observe('main', () => {
        const images = document.querySelectorAll(
            'img[data-src-on-content-visible'
        );

        for (const image of images)  {
            image.src = image.dataset.srcOnContentVisible;
        }
    });

  }
}

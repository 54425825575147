import 'url-search-params-polyfill';
import Swiper, { Autoplay, Navigation } from 'swiper';
import 'swiper/css';
import TomSelect from 'tom-select';
import Cookies from 'js-cookie'
import BigPicture from 'bigpicture'
import 'vanilla-cookieconsent/src/cookieconsent.js'

import Application from './core'
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

$(async () => {
    window.App = new Application()

    // We have to await the initialization, because Vue takes over the
    // rendering and we want to add our own event listeners afterwards
    await window.App.init(window.appUrl)

    // Other code parts can use this to determine that the application is ready
    window.dispatchEvent(new Event('appIsReady'))

    const recaptchaLanguageElement = document.querySelector('meta[name="recaptcha-language"]')

    const recaptchaLanguage = recaptchaLanguageElement
        ? recaptchaLanguageElement.content
        : 'de'

    var captcha = document.createElement('script');
    captcha.setAttribute('src', `https://www.google.com/recaptcha/api.js?hl=${recaptchaLanguage}`)
    document.head.appendChild(captcha);

    const cookieConsent = initCookieConsent();

    cookieConsent.run({
        current_lang: 'de',
        languages: {
            'de': {
                consent_modal: {
                    title: window.dagobertTranslate('cookie_consent_title'),
                    description: window.dagobertTranslate('cookie_consent_description'),
                    primary_btn: {
                        text: window.dagobertTranslate('cookie_consent_action_accept_all'),
                        role: 'accept_all'
                    },
                    secondary_btn: {
                        text: window.dagobertTranslate('cookie_consent_action_settings'),
                        role: 'settings'
                    }
                },
                settings_modal: {
                    title: window.dagobertTranslate('cookie_consent_title_cookie_settings'),
                    save_settings_btn: window.dagobertTranslate('cookie_consent_action_save_settings'),
                    accept_all_btn: window.dagobertTranslate('cookie_consent_action_accept_all'),
                    reject_all_btn: window.dagobertTranslate('cookie_consent_action_reject_all'),
                    close_btn_label: window.dagobertTranslate('cookie_consent_action_close'),
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Expiration'},
                        {col4: 'Description'}
                    ],
                    blocks: [
                        {
                            title: window.dagobertTranslate('cookie_consent_title'),
                            description: window.dagobertTranslate('cookie_consent_description_settings')
                        }, {
                            title: window.dagobertTranslate('cookie_consent_title_necessary_cookies'),
                            description: window.dagobertTranslate('cookie_consent_description_necessary_cookies'),
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true
                            }
                        }, {
                            title: window.dagobertTranslate('cookie_consent_title_analytics_cookies'),
                            description: window.dagobertTranslate('cookie_consent_description_analytics_cookies'),
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false
                            }
                        },  {
                            title: window.dagobertTranslate('cookie_consent_title_marketing_cookies'),
                            description: window.dagobertTranslate('cookie_consent_description_marketing_cookies'),
                            toggle: {
                                value: 'marketing',
                                enabled: false,
                                readonly: false
                            }
                        },
                    ]
                }
            }
        }
    });

    $('.hamburgerMenuIcon').click(function() {
        if(document.getElementById('closeIcon').classList.contains("show")) {
            document.getElementById('hamburgerMenu1').classList.remove('show');
            document.getElementById('hamburgerMenu2').classList.remove('show');
            document.getElementById('hamburgerMenu3').classList.remove('show');
            if(document.getElementById('hamburgerMenu4')) {
                document.getElementById('hamburgerMenu4').classList.remove('show');
            }
        }
        document.getElementById('menuIcon').classList.toggle('show');
        document.getElementById('closeIcon').classList.toggle('show');
        document.getElementById('hamburgerMenu').classList.toggle('show');
    });

    $('.moreHamburger').click(function() {
        var id = this.dataset.id;
        document.getElementById('hamburgerMenu'+id).classList.toggle('show');
    });

    $('.backHamburger').click(function() {
        var id = this.dataset.id;
        document.getElementById('hamburgerMenu'+id).classList.toggle('show');
    });

    $('#showCategories').click(function() {
        $('.leftColCategories').toggle('show')
    });

    $('#hideCategories').click(function() {
        $('.leftColCategories').toggle('show')
    });

    $('.nav-item').mouseover(function() {
        closeAllDropdowns();

        if (this.dataset.id) {
            const id = parseInt(this.dataset.id);
            const dropdown = document.getElementById(`dropdown${id}`);

            // We have to reset the width to be able to cleanly re-calculate
            // each time
            dropdown.style.width = 'auto';

            // The last dropdown (ID 4) is positioned from the right on smaller
            // screen, thus we need an additional offset (equal to the
            // container padding) for the calculation in this case
            const additionalOffset = id === 4 && window.matchMedia('(max-width: 1540.98px)').matches
                ? 15
                : 0;

            dropdown.style.paddingBottom = '15px';

            const dropdownRect = dropdown.getBoundingClientRect();
            const bodyRect = document.body.getBoundingClientRect();

            dropdown.style.width = `${(bodyRect.right - dropdownRect.left + additionalOffset)}px`;

            dropdown.classList.add('show');
        }
    });

    $('header').mouseout(function() {
        closeAllDropdowns();
    });
    if(document.getElementById('verifyContainer')) {
        document.getElementById('verifyContainer').classList.add('show');
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
        ];
        var html = jQuery('html');
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        setTimeout(() => {  document.getElementById('verifyContainer').style.overflow = "auto"; }, 400);
    }

    $('.contactOpen').click(function(event) {
        event.preventDefault();
        document.getElementById('contactContainer').classList.add('show');
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
        ];
        var html = jQuery('html');
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        setTimeout(() => {  document.getElementById('contactContainer').style.overflow = "auto"; }, 400);
    });

    $('#contactClose').click(function(event) {
        event.preventDefault();
        document.getElementById('contactContainer').style.overflow = "hidden";
        document.getElementById('contactContainer').classList.remove('show');
        var html = jQuery('html');
        html.css('overflow', html.data('previous-overflow'));
    });

    $('.loginBTN').click(function(event) {
        event.preventDefault();
        document.getElementById('login-overlay').classList.add('login-overlay--show');
        document.querySelector('#login-overlay .login-content__block--login input[name="username"]').focus();
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
        ];
        var html = jQuery('html');
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        setTimeout(() => {  document.getElementById('login-overlay').classList.add('login-overlay--showing'); }, 400);
    });
    $('#loginOpen2').click(function(event) {
        event.preventDefault();
        if(document.getElementById('closeIcon').classList.contains("show")) {
            document.getElementById('hamburgerMenu1').classList.remove('show');
            document.getElementById('hamburgerMenu2').classList.remove('show');
            document.getElementById('hamburgerMenu3').classList.remove('show');
            if(document.getElementById('hamburgerMenu4')) {
                document.getElementById('hamburgerMenu4').classList.remove('show');
            }
        }
        document.getElementById('menuIcon').classList.toggle('show');
        document.getElementById('closeIcon').classList.toggle('show');
        document.getElementById('hamburgerMenu').classList.toggle('show');

        document.getElementById('login-overlay').classList.add('login-overlay--show');
        document.querySelector('#login-overlay .login-content__block--login input[name="username"]').focus();
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
        ];
        var html = jQuery('html');
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        setTimeout(() => {  document.getElementById('login-overlay').classList.add('login-overlay--showing'); }, 400);
    });

    $('#loginClose').click(function(event) {
        event.preventDefault();
        document.getElementById('login-overlay').classList.remove('login-overlay--showing');
        document.getElementById('login-overlay').classList.remove('login-overlay--show');
        var html = jQuery('html');
        html.css('overflow', html.data('previous-overflow'));
    });

    $('.cancelInvestment').click(function(event) {
        event.preventDefault();
        var id = this.dataset.id;
        document.getElementById('cancelInvestmentContainer'+id).classList.add('show');
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
        ];
        var html = jQuery('html');
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        setTimeout(() => {  document.getElementById('cancelInvestmentContainer'+id).style.overflow = "auto"; }, 400);
    });

    $('.closeCancelInvestment').click(function(event) {
        event.preventDefault();
        var id = this.dataset.id;
        document.getElementById('cancelInvestmentContainer'+id).style.overflow = "hidden";
        document.getElementById('cancelInvestmentContainer'+id).classList.remove('show');
        var html = jQuery('html');
        html.css('overflow', html.data('previous-overflow'));
    });

    $('.changePaymentType').click(function(event) {
        event.preventDefault();
        var id = this.dataset.id;
        document.getElementById('changePaymentTypeContainer'+id).classList.add('show');
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
        ];
        var html = jQuery('html');
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        setTimeout(() => {  document.getElementById('changePaymentTypeContainer'+id).style.overflow = "auto"; }, 400);
    });

    $('.closeChangePaymentType').click(function(event) {
        event.preventDefault();
        var id = this.dataset.id;
        var payment = this.dataset.payment;

        document.getElementById('paymentType1'+id).checked = false;
        document.getElementById('paymentType2'+id).checked = false;

        if(payment == 'INVOICE') {
            document.getElementById('paymentType1'+id).checked = true;
        } else {
            document.getElementById('paymentType2'+id).checked = true;
        }

        document.getElementById('changePaymentTypeContainer'+id).style.overflow = "hidden";
        document.getElementById('changePaymentTypeContainer'+id).classList.remove('show');
        var html = jQuery('html');
        html.css('overflow', html.data('previous-overflow'));
    });

    $('.investNow').click(function(event) {
        event.preventDefault();

        if(document.getElementById('investSelectContainer') !== null) {
            document.getElementById('investSelectContainer').style.overflow = "hidden";
            document.getElementById('investSelectContainer').classList.remove('show');
            var html = jQuery('html');
            html.css('overflow', html.data('previous-overflow'));
        }

        document.getElementById('investContainer').classList.add('show');
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
        ];
        var html = jQuery('html');
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        setTimeout(() => {  document.getElementById('investContainer').style.overflow = "auto"; }, 400);
    });

    $('.investSelect').click(function(event) {
        event.preventDefault();
        document.getElementById('investSelectContainer').classList.add('show');
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
        ];
        var html = jQuery('html');
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        setTimeout(() => {  document.getElementById('investSelectContainer').style.overflow = "auto"; }, 400);
    });

    $('#investClose').click(function(event) {
        event.preventDefault();
        document.getElementById('investContainer').style.overflow = "hidden";
        document.getElementById('investContainer').classList.remove('show');
        var html = jQuery('html');
        html.css('overflow', html.data('previous-overflow'));
    });

    $('#investSelectClose').click(function(event) {
        event.preventDefault();
        document.getElementById('investSelectContainer').style.overflow = "hidden";
        document.getElementById('investSelectContainer').classList.remove('show');
        var html = jQuery('html');
        html.css('overflow', html.data('previous-overflow'));
    });

    $('#thankyouOpen').click(function(event) {
        event.preventDefault();
        document.getElementById('investContainer').style.overflow = "hidden";
        document.getElementById('investContainer').classList.remove('show');
        var html = jQuery('html');
        html.css('overflow', html.data('previous-overflow'));

        document.getElementById('thankyouContainer').classList.add('show');
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
        ];
        var html = jQuery('html');
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        setTimeout(() => {  document.getElementById('thankyouContainer').style.overflow = "auto"; }, 400);
    });

    $('#thankyouClose, .thankyouClose').click(function(event) {
        event.preventDefault();
        document.getElementById('thankyouContainer').style.overflow = "hidden";
        document.getElementById('thankyouContainer').classList.remove('show');
        var html = jQuery('html');
        html.css('overflow', html.data('previous-overflow'));
    });

    $('#registrationSuccessClose').click(function(event) {
        event.preventDefault();
        document.getElementById('registrationSuccess').style.overflow = "hidden";
        document.getElementById('registrationSuccess').classList.remove('show');
        var html = jQuery('html');
        html.css('overflow', html.data('previous-overflow'));
    });

    $('#netIncome').keyup(function(event) {
        checkAndCalculateFields();
    });

    $('#liquidFunds').keyup(function(event) {
        checkAndCalculateFields();
    });

    $('#expenses').keyup(function(event) {
        checkAndCalculateFields();
    });

    $('#noinfo').change(function(event) {
        checkAndCalculateFields();
    });

    if(document.getElementById('netIncome')) {
        checkAndCalculateFields();
    }

    $('#hash_password, #password').keyup(function() {
        let strength = 0;
        let password = document.getElementById('hash_password') ? document.getElementById('hash_password').value : document.getElementById('password').value;
        let capitalLetter = new RegExp('(?=.*[A-Z])');
        let number = new RegExp('(?=.*[0-9])');
        let symbol = new RegExp('(?=.*[$@$!%*#?&])');


        if(password.length >= 8) {
            strength++;
        }
        if(capitalLetter.test(password)) {
            strength++;
        }
        if(number.test(password) || symbol.test(password)) {
            strength++;
        }

        document.getElementById('passStrength1').classList.remove('green');
        document.getElementById('passStrength2').classList.remove('green');
        document.getElementById('passStrength3').classList.remove('green');

        for(let i=1; i<=strength; i++) {
            document.getElementById('passStrength'+i).classList.add('green');
        }
    });

    getDropDownSizes();

    const partnerSliders = document.querySelectorAll('.partners .swiper');

    for (const slider of partnerSliders) {
        const displayMoreItems = slider.classList.contains(
            'display-more-items'
        );

        new Swiper(slider, {
            modules: [Autoplay],
            loop: true,
            autoplay: {
                delay: 3000
            },
            slidesPerView: 3,
            spaceBetween: 30, // equals $grid-gutter-width,
            breakpoints: {
                650: {
                    slidesPerView: 4
                },
                940: {
                    slidesPerView: displayMoreItems
                        ? 4
                        : 3
                },
                1160: {
                    slidesPerView: displayMoreItems
                        ? 6
                        : 4
                }
            }
        });
    }

    const homeCompletedProjects = document.querySelectorAll('#home .completed-projects .swiper');

    for (const slider of homeCompletedProjects) {
        new Swiper(slider, {
            modules: [Autoplay],
            loop: true,
            autoplay: {
                delay: 3000
            },
            slidesPerView: 1,
            spaceBetween: 30, // equals $grid-gutter-width,
            breakpoints: {
                650: {
                    slidesPerView: 2
                },
                1160: {
                    slidesPerView: 3
                }
            }
        });
    }

    const homeCustomerOpinions = document.querySelectorAll('#home .customer-opinions .swiper');

    for (const slider of homeCustomerOpinions) {
        new Swiper(slider, {
            modules: [Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 1,
            spaceBetween: 30, // equals $grid-gutter-width,
            breakpoints: {
                650: {
                    slidesPerView: 2
                },
                1160: {
                    slidesPerView: 3
                }
            }
        });
    }

    const testimonials = document.querySelectorAll('.testimonials .swiper');

    for (const slider of testimonials) {
        new Swiper(slider, {
            modules: [Autoplay, Navigation],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            loop: true,
            autoplay: {
                delay: 3000
            },
            slidesPerView: 1,
            spaceBetween: 30 // equals $grid-gutter-width
        });
    }

    const thankYouOtherProjects = document.querySelector(
        '.investment-overlay__thank-you__other-projects.swiper'
    )

    if (thankYouOtherProjects) {
        new Swiper(thankYouOtherProjects, {
            modules: [Autoplay],
            // loop: true,
            autoplay: {
                delay: 3000
            },
            centerInsufficientSlides: true,
            slidesPerView: 1,
            spaceBetween: 30, // equals $grid-gutter-width,
            breakpoints: {
                650: {
                    slidesPerView: 2
                },
                1160: {
                    slidesPerView: 3
                }
            }
        })
    }

    const formsWithConditionals = document.querySelectorAll(
        '.form-with-conditionals'
    );

    for (const form of formsWithConditionals) {
        initializeFormWithConditionals(form);
    }

    const tomSelects = document.querySelectorAll('.tom-select')

    for (const select of tomSelects) {
        new TomSelect(select, {
            create: false,
            sortField: {
                field: 'text',
                direction: 'asc'
            },
            maxOptions: null // This makes it so all options are visible without filtering
        })
    }

    const videoPreview = document.getElementById('video-preview')

    if (videoPreview) {
        videoPreview.querySelector('.video-preview-close').addEventListener('click', () => {
            Cookies.set('hide-video-preview', 1)
            videoPreview.remove()
        })

        videoPreview.querySelector('video').addEventListener('click', event => {
            BigPicture({
                el: event.target,
                ytSrc: 'KXAqkCmGnWA',
                animationEnd: () => {
                    Cookies.set('hide-video-preview', 1)
                    videoPreview.remove()
                }
            })
        })
    }

    const appBackButtons = document.querySelectorAll('.app-back-button');

    for (const button of appBackButtons) {
        button.addEventListener('click', event => {
            event.preventDefault();
            history.back();
        });
    }

    window.initializeInfoBoxes();

    document.addEventListener('click', event => {
        const infoBoxes = document.querySelectorAll('.dg-info-box[data-initialized]');

        for (const box of infoBoxes) {
            const boxButton = box.querySelector('.dg-info-box-button');
            const boxContent = box.querySelector('.dg-info-box-content');

            if (
                boxButton.contains(event.target) ||
                boxContent.contains(event.target)
            ) {
                return;
            }
        }

        for (const box of infoBoxes) {
            box.classList.remove('active');
        }
    });

    const dgFileInputWrappers = document.querySelectorAll(
        '.dg-file-input-wrapper'
    );

    for (const wrapper of dgFileInputWrappers) {
        const input = wrapper.querySelector('input[type="file"]');
        const selectedFile = wrapper.querySelector(
            '.dg-file-input-selected-file'
        );
        const defaultText = selectedFile.dataset.defaultText;

        input.addEventListener('change', () => {
            const file = input.files.item(0);

            if (file) {
                selectedFile.textContent = file.name;
                return;
            }

            selectedFile.textContent = defaultText;
        });
    }

    const registrationForm = document.querySelector(
        '.registration-fullscreen-view__content__form form'
    );

    if (registrationForm) {
        initializeRegistrationForm(registrationForm);
    }

    const userDataForms = document.querySelectorAll(
        '.user-data__content__form'
    );

    for (const form of userDataForms) {
        initializeUserDataForm(form);
    }

    // If there are multiple forms, we want to close the "edit mode" of all
    // other forms when editing one
    if (userDataForms.length > 1) {
        for (const form of userDataForms) {
            const editButton = form.querySelector(
                '.edit-user-data'
            );

            if (editButton) {
                editButton.addEventListener('click', () => {
                    for (const otherForm of userDataForms) {
                        if (!form.isSameNode(otherForm)) {
                            const editIndicator = otherForm.querySelector(
                                'input[name="is-editing"]'
                            );

                            editIndicator.value = 0;

                            // Change events are not triggered automatically on hidden inputs
                            // See https://www.designcise.com/web/tutorial/how-to-trigger-change-event-on-html-hidden-input-element-using-javascript
                            editIndicator.dispatchEvent(new Event('change'));
                        }
                    }
                })
            }
        }
    }

    const ibanInputs = document.querySelectorAll('.iban-input');

    for (const input of ibanInputs) {
        handleIbanInput(input);
    }

    const lossBearingCapacityCalculator = document.getElementById(
        'loss-bearing-capacity-calculator'
    );

    if (lossBearingCapacityCalculator) {
        initializeLossBearingCapacityCalculator(lossBearingCapacityCalculator);
    }
    $('input[type="text"].datepicker-ui').each(function() {
        const dateFormat = $(this).data('date-format') || 'dd.mm.yy';
        $(this).datepicker({
            monthNames: [
                window.dagobertTranslate('month_january'),
                window.dagobertTranslate('month_february'),
                window.dagobertTranslate('month_march'),
                window.dagobertTranslate('month_april'),
                window.dagobertTranslate('month_may'),
                window.dagobertTranslate('month_june'),
                window.dagobertTranslate('month_july'),
                window.dagobertTranslate('month_august'),
                window.dagobertTranslate('month_september'),
                window.dagobertTranslate('month_october'),
                window.dagobertTranslate('month_november'),
                window.dagobertTranslate('month_december')
            ],
            monthNamesShort: [
                window.dagobertTranslate('month_january_short'),
                window.dagobertTranslate('month_february_short'),
                window.dagobertTranslate('month_march_short'),
                window.dagobertTranslate('month_april_short'),
                window.dagobertTranslate('month_may_short'),
                window.dagobertTranslate('month_june_short'),
                window.dagobertTranslate('month_july_short'),
                window.dagobertTranslate('month_august_short'),
                window.dagobertTranslate('month_september_short'),
                window.dagobertTranslate('month_october_short'),
                window.dagobertTranslate('month_november_short'),
                window.dagobertTranslate('month_december_short')
            ],
            changeMonth: true,
            changeYear: true,
            dateFormat,
        });
    });

    const holderDashboardFilterInput = document.querySelector('#holder-projects-investment-filter input');
    if (holderDashboardFilterInput) {
        const tableRows = document.querySelectorAll('#holder-projects-investments table tbody tr');

        holderDashboardFilterInput.addEventListener('keyup', function () {
            tableRows.forEach(row => row.classList.remove('d-none'));

            const filterValue = holderDashboardFilterInput.value.toLowerCase().trim();

            if (filterValue === '') {
                return;
            }

            tableRows.forEach(row => {
                const name = row.dataset.name.toLowerCase();
                const email = row.dataset.email.toLowerCase();

                if (!name.includes(filterValue) && !email.includes(filterValue)) {
                    row.classList.add('d-none');
                }
            });
        });
    }

    const holderDashboardMarkManualPaybackButtons = document.querySelectorAll('.holder-projects-mark-manual-payback');
    if (holderDashboardMarkManualPaybackButtons) {
        holderDashboardMarkManualPaybackButtons.forEach(button => {
            button.addEventListener('click', function (event) {
                event.preventDefault();

                if (confirm(window.dagobertTranslate('confirm_mark_manual_payback'))) {
                    window.location.href = this.href;
                }
            });
        });
    }

    // We wait for a few seconds before attempting to fix the GTranslate edit
    // interface to give other elements (like sliders) time to initialize
    setTimeout(() => { fixGTranslateEditInterface(); }, 5000);
});

$(window).on('resize', function () {
    getDropDownSizes();
});

function getDropDownSizes() {
    if(document.getElementById('dropdown1')) {
        var menu1 = document.getElementById('dropdown1').getBoundingClientRect();
        var menu2 = document.getElementById('dropdown2').getBoundingClientRect();
        var menu3 = document.getElementById('dropdown3').getBoundingClientRect();
        if(document.getElementById('dropdown4')) {
            var menu4 = document.getElementById('dropdown4').getBoundingClientRect();
        }

        var body = document.body.getBoundingClientRect();

        document.getElementById('dropdown1').style.width = (body.right - menu1.left) + "px";
        document.getElementById('dropdown2').style.width = (body.right - menu2.left) + "px";
        document.getElementById('dropdown3').style.width = (body.right - menu3.left) + "px";
        if(document.getElementById('dropdown4')) {
            document.getElementById('dropdown4').style.width = (body.right - menu4.left) + "px";
        }
    }
}

function closeAllDropdowns() {
    if(document.getElementById('navitem1')) {
        document.getElementById('navitem1').style.paddingBottom = "0px";
        document.getElementById('navitem2').style.paddingBottom = "0px";
        document.getElementById('navitem3').style.paddingBottom = "0px";
        if(document.getElementById('navitem4')) {
            document.getElementById('navitem4').style.paddingBottom = "0px";
        }

        document.getElementById('dropdown1').classList.remove('show');
        document.getElementById('dropdown2').classList.remove('show');
        document.getElementById('dropdown3').classList.remove('show');
        if(document.getElementById('dropdown4')) {
            document.getElementById('dropdown4').classList.remove('show');
        }
    }
}

function initializeInfoBox (box) {
    box.dataset.initialized = true;

    const button = box.querySelector('.dg-info-box-button button');
    const content = box.querySelector('.dg-info-box-content');
    const closeButton = content.querySelector('.dg-info-box-close button');

    // Prevent anchors from being activated when clicking somewhere on the
    // box when the box is nested in an anchor
    box.addEventListener('click', event => {
        event.preventDefault();
    });

    button.addEventListener('click', event => {
        event.preventDefault();

        box.classList.toggle('active');

        for (const otherBox of document.querySelectorAll('.dg-info-box[data-initialized]')) {
            if (!box.isSameNode(otherBox)) {
                otherBox.classList.remove('active');
            }
        }
    });

    closeButton.addEventListener('click', event => {
        event.preventDefault();

        box.classList.remove('active');
    });
}

window.initializeInfoBoxes = function () {
    const infoBoxes = document.querySelectorAll(
        '.dg-info-box:not([data-initialized])'
    );

    for (const box of infoBoxes) {
        initializeInfoBox(box);
    }
};

function initializeRegistrationForm (form) {
    if (!form) {
        return;
    }

    const registrationTypeIndicator = document.getElementById(
        'provide-company-data'
    );

    const isCompanyRegistration = parseInt(registrationTypeIndicator.value) === 1;

    const registrationTypeToggles = form.querySelectorAll(
        '.registration-type-toggles button'
    );

    for (const toggle of registrationTypeToggles) {
        toggle.addEventListener('click', () => {
            if (toggle.disabled) {
                // Disabled buttons shouldn't be clickable, but let's still
                // have this failsafe
                return;
            }

            const previousRegistrationType = registrationTypeIndicator.value;

            registrationTypeIndicator.value = toggle.dataset.registrationType;
            // Change events are not triggered automatically on hidden inputs
            // See https://www.designcise.com/web/tutorial/how-to-trigger-change-event-on-html-hidden-input-element-using-javascript
            registrationTypeIndicator.dispatchEvent(new Event('change'));

            toggle.disabled = true;
            toggle.classList.remove('colors-inverted');
            toggle.classList.add('has-icon');
            toggle.querySelector('.dg-button-content').insertAdjacentHTML(
                'beforeend',
                '<i class="icon-confirm"></i>'
            );

            const otherToggle = form.querySelector(
                `.registration-type-toggles button[data-registration-type="${previousRegistrationType}"]`
            );

            otherToggle.disabled = false;
            otherToggle.classList.add('colors-inverted');
            otherToggle.classList.remove('has-icon');
            otherToggle.querySelector('.dg-button-content i').remove();
        });
    }

    const passwordFields = form.querySelectorAll(
        '.registration-field--password'
    );

    for (const field of passwordFields) {
        field.querySelector('i').addEventListener('click', event => {
            const currentInputType = field.querySelector('input').type;

            switch (currentInputType) {
                case 'password':
                    field.querySelector('input').type = 'text';
                    event.target.classList.remove('icon-eye-open');
                    event.target.classList.add('icon-eye-closed');
                    break;
                case 'text':
                    field.querySelector('input').type = 'password';
                    event.target.classList.remove('icon-eye-closed');
                    event.target.classList.add('icon-eye-open');
            }
        });
    }

    const passwordInput = document.getElementById('registration-password');
    const passwordStrength = form.querySelector('.password-strength');

    passwordInput.addEventListener('keyup', () => {
        const password = passwordInput.value;

        if (password === '') {
            passwordStrength.classList.add('d-none');
            return;
        }

        passwordStrength.classList.remove('d-none');

        let strength = 0;
        const capitalLetter = new RegExp('(?=.*[A-Z])');
        const number = new RegExp('(?=.*[0-9])');
        const symbol = new RegExp('(?=.*[$@$!%*#?&])');

        if (password.length >= 8) {
            strength++;
        }
        if(capitalLetter.test(password)) {
            strength++;
        }
        if(number.test(password) || symbol.test(password)) {
            strength++;
        }

        const indicators = passwordStrength.querySelectorAll(
            '.password-strength__indicator'
        );

        for (const indicator of indicators) {
            indicator.classList.remove('password-strength__indicator--active');
        }

        if (strength >= 3) {
            passwordStrength
                .querySelector('.password-strength__indicator--strong')
                .classList.add('password-strength__indicator--active')
        } else if (strength >= 2) {
            passwordStrength
                .querySelector('.password-strength__indicator--medium')
                .classList.add('password-strength__indicator--active')
        } else {
            passwordStrength
                .querySelector('.password-strength__indicator--weak')
                .classList.add('password-strength__indicator--active')
        }
    })
}

function initializeUserDataForm (form) {
    if (!form) {
        return;
    }

    const editIndicator = form.querySelector('input[name="is-editing"]');

    const editButton = form.querySelector(
        '.edit-user-data'
    );

    if (editButton) {
        editButton.addEventListener('click', () => {
            editIndicator.value = 1;

            // Change events are not triggered automatically on hidden inputs
            // See https://www.designcise.com/web/tutorial/how-to-trigger-change-event-on-html-hidden-input-element-using-javascript
            editIndicator.dispatchEvent(new Event('change'));

            const existingMessage = form.querySelector('.user-data__message');

            if (existingMessage) {
                // We remove any existing success message if the user edits
                // again to prevent potential confusion about the save status
                existingMessage.remove()
            }
        });
    }

    const cancelEditButton = form.querySelector(
        '.cancel-edit-user-data'
    );

    if (cancelEditButton) {
        cancelEditButton.addEventListener('click', () => {
            editIndicator.value = 0;

            // Change events are not triggered automatically on hidden inputs
            // See https://www.designcise.com/web/tutorial/how-to-trigger-change-event-on-html-hidden-input-element-using-javascript
            editIndicator.dispatchEvent(new Event('change'));

            const existingMessage = form.querySelector('.user-data__message');

            if (existingMessage) {
                // We remove any existing message if the user cancels the edit
                // to prevent potential confusion about the save status
                existingMessage.remove()
            }
        });
    }

    const inputs = form.querySelectorAll(
        '.dg-input-wrapper input, .dg-input-wrapper select'
    );

    for (const input of inputs) {
        // Clear potential errors on input
        input.addEventListener('change', () => {
            if (input.parentNode.classList.contains('has-error')) {
                input.parentNode.classList.remove('has-error');

                const sibling = input.parentNode.nextElementSibling;

                if (sibling && sibling.classList.contains('dg-input-error')) {
                    sibling.remove();
                }
            }
        });
    }
}

function handleIbanInput (input) {
    if (!input) {
        return;
    }

    const isDgInput = input.parentNode.classList.contains('dg-input-wrapper');

    const bicInput = input.dataset.bicInput
        ? document.getElementById(input.dataset.bicInput)
        : null;

    input.addEventListener('keyup', () => {
        input.value = input.value
            .replace(/\s/g,'')
            .toUpperCase()
            .replace(/(.{4})/g, '$1 ')
            .trim();
    });

    input.addEventListener('blur', () => {
        const iban = input.value.replace(/\s/g, '');

        if (input.dataset.oldIban && input.dataset.oldIban === iban) {
            return;
        }

        input.dataset.oldIban = iban;

        if (isDgInput) {
            input.parentNode.classList.remove('has-icon');
            input.parentNode.classList.remove('has-error');

            const icon = input.parentNode.querySelector('i');
            if (icon) {
                icon.remove();
            }
        }

        $.getJSON('https://openiban.com/validate/' + iban + '?getBIC=true&validateBankCode=true', data => {
            if (!data.valid) {
                // FIXME: disable the error indicator until we have a more
                // reliable IBAN checking solution again.
                // if (isDgInput) {
                //     input.parentNode.classList.add('has-error');
                // }

                return;
            }

            if (isDgInput) {
                input.parentNode.classList.add('has-icon');
                input.insertAdjacentHTML(
                    'afterend', '<i class="icon-confirm"</i>'
                );
            }

            if (bicInput && typeof data.bankData.bic !== 'undefined') {
                let bicValue = data.bankData.bic;

                // FIXME: this should probably be removed at some point.
                if (bicValue === 'EASYATW1XXX') {
                    bicValue = 'BAWAATWW';
                }

                bicInput.value = bicValue;
            }
        });
    })

    input.dispatchEvent(new Event('blur'));
}

function initializeLossBearingCapacityCalculator (lossBearingCapacityCalculator) {
    if (!lossBearingCapacityCalculator) {
        return;
    }

    const inputs = lossBearingCapacityCalculator.querySelectorAll(
        'input[type="text"]:not([readonly])'
    );

    const netIncomeInput = document.getElementById(
        'loss-bearing-capacity-net-income'
    );
    const liquidFundsInput = document.getElementById(
        'loss-bearing-capacity-liquid-funds'
    );
    const yearlyExpensesInput = document.getElementById(
        'loss-bearing-capacity-yearly-expenses'
    );
    const netCapitalInput = document.getElementById(
        'loss-bearing-capacity-net-capital'
    );
    const resultValue = document.getElementById(
        'loss-bearing-capacity-result-value'
    );
    const resultValueText = document.getElementById(
        'loss-bearing-capacity-result-value-text'
    );

    for (const input of inputs) {
        input.addEventListener('focus', event => {
            event.target.value = event.target.value.replace('EUR ', '');
            event.target.value = event.target.value.replace('.', '');

            if (event.target.value == 0) {
                event.target.value = '';
            }
        });

        input.addEventListener('keypress', event => {
            if (isNaN(event.key)) {
                event.preventDefault();
            }
        });

        input.addEventListener('blur', event => {
            event.target.value = event.target.value
                .split('.')
                .join('')
                .split(',')
                .join('');

            if (event.target.value.trim() === '' || isNaN(event.target.value)) {
                event.target.value = 0;
            }

            event.target.dataset.integerValue = event.target.value;

            event.target.value = 'EUR ' + new Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            ).format(event.target.value);

            let netCapital = parseInt(netIncomeInput.dataset.integerValue) +
                parseInt(liquidFundsInput.dataset.integerValue) -
                parseInt(yearlyExpensesInput.dataset.integerValue);

            if (netCapital < 0) {
                netCapital = 0;
            }

            netCapitalInput.dataset.integerValue = netCapital;

            netCapitalInput.value = 'EUR ' + new Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            ).format(netCapitalInput.dataset.integerValue);

            netCapitalInput.dispatchEvent(new Event('change'));
        });

        netCapitalInput.addEventListener('change', () => {
            resultValue.innerText = 'EUR ' + new Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            ).format(netCapitalInput.dataset.integerValue / 100 * 10);
            resultValueText.innerText = 'EUR ' + new Intl.NumberFormat(
                'de-DE',
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            ).format(netCapitalInput.dataset.integerValue / 100 * 10);
        })

        // We manually fire this once to trigger the calculation when opening
        // the page
        netCapitalInput.dispatchEvent(new Event('change'));
    }
}

function initializeFormWithConditionals (form) {
    if (!form) {
        return;
    }

    let currentlyMetConditions = [];

    const updateCurrentlyMetConditions = (condition, met = false) => {
        if (!met) {
            currentlyMetConditions = currentlyMetConditions.filter(
                currentlyMetCondition => currentlyMetCondition !== condition
            );

            toggleConditionalParts();

            return;
        }

        currentlyMetConditions.push(condition);

        toggleConditionalParts();
    };

    const toggleConditionalParts = () => {
        const allParts = form.querySelectorAll('[data-display-if]');
        const allNegatedParts = form.querySelectorAll('[data-display-not-if]');

        const showableParts = [];
        const hideableParts = [];

        for (const part of allParts) {
            const partConditions = part.dataset.displayIf.split('&&');

            let conditionsMet = true;

            for (const partCondition of partConditions) {
                if (!currentlyMetConditions.includes(partCondition)) {
                    conditionsMet = false;
                    break;
                }
            }

            if (conditionsMet) {
                showableParts.push(part);
                continue;
            }

            hideableParts.push(part);
        }

        // Negated parts are dealt with afterwards, so they'll effectively
        // overrule if an element has both a regular and a negated condition
        for (const part of allNegatedParts) {
            const partConditions = part.dataset.displayNotIf.split('&&');

            let conditionsMet = true;

            for (const partCondition of partConditions) {
                if (currentlyMetConditions.includes(partCondition)) {
                    conditionsMet = false;
                    break;
                }
            }

            if (conditionsMet) {
                showableParts.push(part);
                continue;
            }

            hideableParts.push(part);
        }

        const disableableElements = [
            'input',
            'textarea',
            'select',
            'button',
            'fieldset',
        ];

        for (const part of hideableParts) {
            const isDisableableElement = disableableElements.includes(
                part.nodeName.toLowerCase()
            );

            part.classList.add('d-none');

            if (isDisableableElement) {
                part.disabled = true;
            }
        }

        for (const part of showableParts) {
            // If the part was already hidden because of some other rule, we
            // we can skip showing it again in the same update cycle
            if (hideableParts.includes(part)) {
                continue;
            }

            const isDisableableElement = disableableElements.includes(
                part.nodeName.toLowerCase()
            );

            part.classList.remove('d-none');

            if (isDisableableElement) {
                part.disabled = false;
            }
        }
    };

    const conditionalToggles = form.querySelectorAll('[data-toggle]');

    for (const toggle of conditionalToggles) {
        const condition = toggle.dataset.toggle;
        const isSelect = toggle.matches('select');
        const isHidden = toggle.matches('input[type="hidden"]');
        const isRadio = toggle.matches('input[type="radio"]');

        let toggleOnValues;

        if (isSelect || isHidden) {
            if (!toggle.hasAttribute('data-toggle-on-values')) {
                continue;
            }

            toggleOnValues = toggle.dataset.toggleOnValues.split('|');
        }

        const toggledOn = (isSelect || isHidden)
            ? toggleOnValues.includes(toggle.value)
            : toggle.checked;

        updateCurrentlyMetConditions(condition, toggledOn);

        toggle.addEventListener('change', () => {
            // We need to update all radios with the same name when one changes
            if (isRadio && toggle.name) {
                for (const radio of form.querySelectorAll(`input[name="${toggle.name}"]`)) {
                    updateCurrentlyMetConditions(
                        radio.dataset.toggle,
                        radio.checked
                    );
                }

                return;
            }

            const toggledOn = (isSelect || isHidden)
                ? toggleOnValues.includes(toggle.value)
                : toggle.checked;

            updateCurrentlyMetConditions(condition, toggledOn);
        });
    }
}

function checkAndCalculateFields() {
    var noinfocheck = document.getElementById('noinfo');
    if(noinfocheck && noinfocheck.checked === true) {
        if(document.getElementById("submitlossBearingCapacity")){
            document.getElementById("submitlossBearingCapacity").classList.remove("d-none");
        }
        document.getElementById("checkboxAgreeWithLoss").classList.add("d-none");
    } else {
        var netIncome = document.getElementById('netIncome').value;
        var liquidFunds = document.getElementById('liquidFunds').value;
        var expenses = document.getElementById('expenses').value;

        netIncome = netIncome.replace(",", ".");
        liquidFunds = liquidFunds.replace(",", ".");
        expenses = expenses.replace(",", ".");

        if(netIncome != '' && liquidFunds != '' && expenses != '') {
            document.getElementById("checkboxAgreeWithLoss").classList.remove("d-none");
            if(document.getElementById("submitlossBearingCapacity")) {
                document.getElementById("submitlossBearingCapacity").classList.remove("d-none");
            }
            let calculation = (parseFloat(netIncome) + parseFloat(liquidFunds) - parseFloat(expenses)) / 10;
            document.getElementById("calculatedNetWorth").innerHTML = new Intl.NumberFormat('de-DE').format(calculation);
        } else {
            document.getElementById("checkboxAgreeWithLoss").classList.add("d-none");
            if(document.getElementById("submitlossBearingCapacity")) {
                document.getElementById("submitlossBearingCapacity").classList.add("d-none");
            }
        }
    }
}

function fixGTranslateEditInterface () {
    // FIXME: Some things don't work then GTranslate initializes its edit
    // interface, likely due to Vue taking over the rendering.
    //
    // Ideally, we would wrap all of the JS added by GTranslate in a
    // `window.ensureAppIsReady()` to make sure it runs after Vue
    // initialization has finished, but we probably can't do that in a nice way
    // due to the GTranslate JS being added via their proxy (so after we send
    // our HTTP response).
    //
    // As a workaround, we initialize some of the things again here.

    // If the floating edits element does not exist we know that we are not
    // using the GTranslate edit interface and can therefore abort
    if (!document.getElementById('gt-floating-edits')) {
        return;
    }

    console.log('Fixing GTranslate edit interface...');

    const editUrlButtons = document.querySelectorAll('.gicons-url');

    for (const button of editUrlButtons) {
        button.addEventListener('click', event => {
            const originalUrl = button.getAttribute('data-gt-orig-uri');
            const translatedUrl = button.getAttribute('data-gt-trans-uri');

            if (!(originalUrl && translatedUrl)) {
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            openEditUrl(originalUrl, translatedUrl);
        });
    }

    // This following code is taken unmodified from the injected GTranslate
    // script
    var gt_imgs = document.getElementsByTagName('img');
    for(var i = 0; i < gt_imgs.length; i++) {
        if(gt_imgs[i].hasAttribute('data-gt-src-hash') && (gt_imgs[i].width + gt_imgs[i].height) > 100) {
            var img = document.createElement('img');
            img.src='//tdns.gtranslate.net/tdn-static2/images/edit.png';
            img.width = 12;
            img.height = 12;
            img.title = gt_imgs[i].getAttribute('data-gt-content');
            img.style.position = 'absolute';
            img.style.top = '10px';
            img.style.left = '10px';
            img.style.zIndex = 23887;

            img.setAttribute('onmouseover', 'toggleHighlightText(this)');
            img.setAttribute('onmouseout', 'toggleHighlightText(this)');
            img.setAttribute('data-gt-hash', gt_imgs[i].getAttribute('data-gt-src-hash'));
            img.setAttribute('class', 'gicons');
            img.setAttribute('data-gt-orig-text', gt_imgs[i].getAttribute('data-gt-content'));

            gt_imgs[i].name = gt_imgs[i].getAttribute('data-gt-src-hash');
            gt_imgs[i].outerHTML = '<div style="position:relative;">'+gt_imgs[i].outerHTML+'</div>';
            gt_imgs[i].parentNode.appendChild(img);
        }

        if(gt_imgs[i].getAttribute('data-gt-hash')) gt_imgs[i].onclick = function(e) {e.preventDefault();e.stopPropagation();openEdit(this.getAttribute('data-gt-hash'), this.getAttribute('data-gt-orig-text'));};
        else if(gt_imgs[i].getAttribute('data-gt-hash-cart')) gt_imgs[i].onclick = function(e) {e.preventDefault();e.stopPropagation();addToBasket(this.getAttribute('data-gt-hash-cart'), '');};
        else if(gt_imgs[i].getAttribute('data-gt-orig-uri')) gt_imgs[i].onclick = function(e) {e.preventDefault();e.stopPropagation();openEditUrl(this.getAttribute('data-gt-orig-uri'), this.getAttribute('data-gt-trans-uri'));};

        if(typeof gt_ht_list[gt_imgs[i].getAttribute('data-gt-hash')] != 'undefined') gt_imgs[i].setAttribute('src', '//tdns.gtranslate.net/tdn-static2/images/edit2.png');
    }
}
